<template>
  <div class="flex flex-col text-left">
    <div class="flex flex-col">
      <div class="w-full flex space-x-2">
        <div class="w-full flex flex-col">
          <label for="">Kategorie</label>
          <input
            type="text"
            v-model="obligation.categorie"
            class="mb-2 border rounded px-2 py-1 text-xs appearance-none focus:outline-none bg-transparent"
          />
        </div>
      </div>

      <div class="w-full flex space-x-2">
        <div class="w-full flex flex-col">
          <label for="">Pflicht</label>
          <textarea
            v-model="obligation.obligation"
            rows="7"
            class="mb-2 border rounded px-2 py-1 text-xs appearance-none focus:outline-none bg-transparent"
            :class="{ 'border-red-500': $v.obligation.obligation.$error }"
          ></textarea>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { createOne, updateOne } from '@/services/obligation'

const tempObligation = {
  categorie: '',
  obligation: '',
}

export default {
  name: 'new-obligation',
  props: ['payload', 'parent_id'],
  components: {},
  data() {
    return {
      obligation: { ...tempObligation },
    }
  },
  validations() {
    return {
      obligation: {
        obligation: {
          required,
        },
      },
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.obligation = { ...this.payload }
    },
    save() {
      this.$v.obligation.$touch()

      if (this.$v.$invalid) {
        this.$toast.warning('Bitte füllen Sie die erforderlichen Felder aus')
      } else {
        this.obligation['roleprofile'] = this.parent_id
        if (this.obligation.id) {
          updateOne(this.obligation).then(() => {
            this.$emit('close-modal', { show: false })
            // this.$toast.success(response.data.message)
          })
        } else {
          createOne(this.obligation).then(() => {
            this.$emit('close-modal', { show: false })
            // this.$toast.success(response.data.message)
          })
        }
      }
    },
  },
  watch: {},
}
</script>
